.accordion_wrapper {
    background-color: var(--darkBlue);
}

.accordion-item {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    background-color: var(--darkGray) !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    color: white !important;
    border-bottom: clamp(1px, 0.2vw, 3px) solid black !important;
    margin: var(--componentsPadding) !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

// Hier is accordion bar
button.accordion-button:focus,
button.accordion-button {
    background-color: var(--darkGray) !important;
}

button.accordion-button {
    box-shadow: none !important;
    font-size: var(--sectionTitleFontSize);
    font-weight: 500;
    transition: 1s !important;
    color: white;
    padding: 0 !important;
    padding-bottom: clamp(10px, 2%, 20px) !important;
    padding-top: clamp(10px, 2%, 20px) !important;
}
.accordion-body {
    padding: 0 !important;
    padding-bottom: clamp(20px, 3%, 40px) !important;
}

button.accordion-button:not(.collapsed) {
    padding-bottom: clamp(20px, 3%, 40px) !important;
    color: white;
}

button.accordion-button:after {
    background-image: url("../../assets/leftArrow.svg") !important;
    // in order to change the size of arrow
    background-size: clamp(20px, 2.4vw, 38px);
    width: clamp(15px, 1.5vw, 50px);
    height: clamp(23px, 2.4vw, 35px);
}

button.accordion-button:not(.collapsed):after {
    background-image: url("../../assets/leftArrow.svg") !important;
    transform: rotate(-90deg) !important;
}
