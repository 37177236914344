.modal {
    background-color: rgba(0, 23, 75, 0.4);
}

.modal_footer {
    display: flex;
    justify-content: start !important;
}

.backdrop-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darken the background */
    backdrop-filter: blur(10px); /* Apply blur effect */
    z-index: 1040; /* Ensure the backdrop is below the modal but above other content */
}

.modal-backdrop.show {
    display: none; /* Hide default modal backdrop */
}

.modal_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal_logo {
    width: 50%;
}

.modal_text {
    align-self: flex-start;
}
