.form_alert {
    text-align: center;
}

.email_form {
    width: 100%;
}

.form_group {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
}

.email_input {
    width: 100%;
    margin-right: 1%;
    border: 1px solid black;
    padding: 1%;
    border-radius: 5px;
    &:focus {
        outline: none;
    }
}

.email_form_button {
    background-color: var(--darkBlue) !important;
    &:active {
        outline: none;
    }
}
