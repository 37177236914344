.Error {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    margin-bottom: auto;
}

#not-found-text {
    color: black;
    text-align: center;
}
