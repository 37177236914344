.section_title_icon {
    width: var(--titleIconWidth);
    display: inline-block;
    font-size: var(--iconFontSize);
    padding-right: var(--sectionTitleIconPadding);
}

.section_title {
    font-size: var(--primaryTitleFontSize);
}
