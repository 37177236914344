.snapshot_wrapper {
    background-image: url("../../assets/bwt-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--componentsPadding);
    overflow: hidden;
}

.icon_wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.component_icon {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.title_text_wrapper {
    text-align: center;
}

.component_title {
    font-size: var(--primaryTitleFontSize);
    font-weight: var(--primaryFontWeight);
    color: var(--darkBlue);
}

.component_order_number {
    font-size: var(--secondaryTitleFontSize);
    font-weight: var(--secondaryFontWeight);
}
