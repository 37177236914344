.info_pair {
    display: flex;
    flex-direction: row;
    font-size: var(--primaryTextFontSize);
}

.info_key {
    flex: 1;
}
.info_value {
    flex: 1;
}

.info_pair:nth-child(even) {
    background-color: var(--pink);
}
