.action_group {
    padding: var(--componentsPadding);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 3vw;
}

.share {
    cursor: pointer;
    width: 47.5%;
    max-width: 310px;
    aspect-ratio: 31/19;
    display: flex;
    flex-direction: row;
    background-color: #052a68;
    text-decoration: none;
}

.customer_portal {
    background-color: var(--grey);
    text-decoration: none;
}

.share_text {
    flex: 1;
    color: white;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    width: 100%;
}

.share_title {
    flex: 2;
    display: flex;
    align-items: flex-end;
    padding-left: 5%;
    width: 100%;
    font-size: var(--primaryTextFontSize);
    font-weight: 500;
    word-break: break-word;
}

.share_section_icon {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.share_icon_wrapper {
    flex: 1;
    height: 100% !important;
    overflow: hidden;
}

.analyse_icon_wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share_icon {
    width: 150%;
    color: rgb(255, 255, 255) !important;
    height: 145%;
    transform: rotate(180deg);
    margin-top: -28%;
    margin-left: -19%;
}

.analyse_icon {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

@media screen and (min-width: 1200px) {
    .share_title {
        padding-left: 10%;
    }

    .share_section_icon {
        margin-left: 10%;
    }
}

@media screen and (max-width: 576px) {
    .share_title {
        padding-left: 3%;
    }

    .share_section_icon {
        margin-left: 3%;
    }
}
