.header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--componentsPadding);
    background-color: white;
}

.manufacturer_text_logo {
    width: 25%;
    max-width: 300px;
    min-width: 220px;
}
